.narrow {
  width: 100%;
  margin: 0 auto;
}

.page-padding {
  padding: 4rem;
}

.total-amount {
  display: block;
  margin-bottom: 14px;
}

button {
  width: 100%;
  padding: 5px;
  border: 1px solid;
  border-radius: 5px;
  background-color: #fff;
}
button:hover {
  background-color: #efefef;
}
