.narrow {
  width: 100%;
  margin: 0 auto;
}

.page-padding {
  padding: 4rem;
}

.add-to-team-img {
  width: 100%;
}
